<template>
  <v-app-bar
    absolute
    app
    color="transparent"
    flat
    height="75"
    id="app-bar"
  >
    <v-btn
      @click="setDrawer(!drawer)"
      class="mr-3"
      elevation="1"
      fab
      small
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-progress-linear
      :active="appLoading"
      absolute
      bottom
      color="primary accent-4"
      indeterminate
    />
    <v-spacer/>
    <div class="mx-3"/>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            bordered
            color="red"
            overlap
          >
            <template
              v-slot:badge
              v-if="notificaciones !== []"
            >
              <span>{{ notificaciones.length }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div style="max-width: 450px">
          <v-list-item
            three-line
            :key="`item-${i}`"
            :to="{name: 'Dispositivo', params: {codigo: notificacion.sensor.codigo_dispositivo}}"
            v-for="(notificacion, i) in notificaciones"
          >
            <v-list-item-content>
              <v-list-item-subtitle
                style="-webkit-line-clamp: unset !important;"
                v-text="notificacion.mensaje"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      offset-y
      open-on-hover
      origin="top right"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :to="{name: 'Perfil de Usuario'}"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
      >
        <v-list>
          <v-list-item
            :to="{name: 'Perfil de Usuario'}"
            v-if="user"
          >
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ user.first_name }} {{ user.last_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="doLogout"
            v-if="user"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  // import { VHover, VListItem } from 'vuetify/lib'
  // Utilities
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { Client } from '@pusher/push-notifications-web/dist/push-notifications-esm'

  const beamsClient = new Client({
    instanceId: '644154b0-a21a-450a-97db-7b04a785a500',
  })

  export default {
    name: 'DashboardCoreAppBar',
    //
    // components: {
    //   AppBarItem: {
    //     render (h) {
    //       return h(VHover, {
    //         scopedSlots: {
    //           default: ({ hover }) => {
    //             return h(VListItem, {
    //               attrs: this.$attrs,
    //               class: {
    //                 'black--text': !hover,
    //                 'white--text secondary elevation-12': hover,
    //               },
    //               props: {
    //                 activeClass: '',
    //                 dark: hover,
    //                 link: true,
    //                 ...this.$attrs,
    //               },
    //             }, this.$slots.default)
    //           },
    //         },
    //       })
    //     },
    //   },
    // },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({}),
    computed: {
      ...mapState(['drawer', 'appLoading', 'tituloAplicacion']),
      ...mapState('perfil', ['user', 'empresa', 'notificaciones']),
    },
    async beforeMount () {
      await this.fetchNotificaciones()
      const fetchNotificacionesTimeout = () => {
        setTimeout(async () => {
          await this.fetchNotificaciones()
          fetchNotificacionesTimeout()
        }, 10000)
      }
      fetchNotificacionesTimeout()
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      ...mapActions(['logout']),
      ...mapActions('perfil', ['fetchNotificaciones']),
      async doLogout () {
        beamsClient.start()
          .then(() => beamsClient.removeDeviceInterest(this.empresa.codigo))
        // .catch(e => console.error('Could not remove device interest', e))
        await this.logout()
        this.$router.push('/login')
      },
    },
  }
</script>
